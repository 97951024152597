// /**
//  * @description 路由守卫，目前两种模式：all模式与intelligence模式
//  */
// import router from '@/router'
// import store from '@/store'
// import VabProgress from 'nprogress'
// import 'nprogress/nprogress.css'
// import getPageTitle from '@/utils/pageTitle'
// import { toLoginRoute } from '@/utils/routes'
// // import { authentication, routesWhiteList } from '@/config'
// import { authentication } from '@/config'

// import { getToken } from '@/utils/auth' //  从cookie中获取token

// VabProgress.configure({
//   easing: 'ease',
//   speed: 500,
//   trickleSpeed: 200,
//   showSpinner: false,
// })
// const whiteList = ['/login']
// router.beforeEach(async (to, from, next) => {
//   console.log(to)
//   const { showProgressBar } = store.getters['settings/theme']
//   if (showProgressBar) VabProgress.start()
//   const hasToken = getToken()

//   if (hasToken) {
//     if (store.getters['routes/routes'].length) {
//       console.log(1)
//       if (to.path === '/login') {
//         console.log(11)
//         next({ path: '/' })
//       } else {
//         console.log(22)
//         next()
//       }
//     } else {
//       console.log(2)
//       try {
//         await store.dispatch('user/getUserInfo')
//         await store.dispatch('routes/setRoutes', authentication)
//         next({ ...to, replace: true })
//       } catch (err) {
//         console.error('vue-admin-beautiful错误拦截:', err)
//         await store.dispatch('user/resetAll')
//         next(toLoginRoute(to.path))
//       }
//     }
//   } else {
//     console.log('没有hasToken==')
//     // console.log(routesWhiteList)
//     if (whiteList.includes(to.path)) {
//       console.log(4)
//       next()
//     } else {
//       console.log(5)
//       next({ path: '/login' })
//     }
//   }
// })
// router.afterEach((to) => {
//   document.title = getPageTitle(to.meta.title)
//   if (VabProgress.status) VabProgress.done()
// })
