/**
 * @description 路由守卫，目前两种模式：all模式与intelligence模式
 */
import router from '@/router'
import store from '@/store'
import VabProgress from 'nprogress'
import 'nprogress/nprogress.css'
// import getPageTitle from '@/utils/pageTitle'
import { toLoginRoute } from '@/utils/routes'

import {
  // authentication,
  loginInterception,
  routesWhiteList,
  supportVisit,
} from '@/config'

VabProgress.configure({
  easing: 'ease',
  speed: 500,
  trickleSpeed: 200,
  showSpinner: false,
})

router.beforeEach(async (to, from, next) => {
  const { showProgressBar } = store.getters['settings/theme']
  if (showProgressBar) VabProgress.start()
  let hasToken = store.getters['user/token']

  // 刷新操作的执行流程是4=>1=>3  普通页面切换就是1=>3
  if (hasToken) {
    if (store.getters['routes/routes'].length) {
      // console.log(1)
      // console.log(to)
      // 禁止已登录用户返回登录页
      if (to.path === '/login') {
        console.log(2)
        next({ path: '/' })
        if (showProgressBar) VabProgress.done()
      } else {
        // console.log(3)
        // next()
        if (to.path === '/') {
          // next('/admin/home')
          next('/welcome')
          VabProgress.done()
        } else {
          next()
        }
      }
    } else {
      try {
        // console.log(4)
        if (loginInterception) await store.dispatch('user/getUserInfo')
        // config/setting.config.js loginInterception为false(关闭登录拦截时)时，创建虚拟角色
        else await store.dispatch('user/setVirtualRoles')
        // 根据路由模式获取路由并根据权限过滤
        // await store.dispatch('routes/setRoutes', authentication) // (旧)

        const res = await store.dispatch('routes/getMenuList') // (新改造)
        // console.log(res)
        await store.dispatch('routes/setRoutes', res)

        next({ ...to, replace: true })
      } catch (err) {
        console.log(5)
        console.error('vue-admin-beautiful错误拦截:', err)
        await store.dispatch('user/resetAll')
        next(toLoginRoute(to.path))
      }
    }
  } else {
    if (routesWhiteList.includes(to.path)) {
      console.log(6)
      // 设置游客路由(不需要可以删除)
      if (supportVisit && !store.getters['routes/routes'].length) {
        await store.dispatch('routes/setRoutes', 'visit')
        next({ ...to, replace: true })
      } else next()
    } else next(toLoginRoute(to.path))
  }
})
router.afterEach(() => {
  // console.log(to)
  // document.title = getPageTitle(to.meta.title) // 网页标题
  if (VabProgress.status) VabProgress.done()
})
